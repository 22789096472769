let all_products = [
  {
    id: 1,
    clubOrganisingName : 'gdsc',
    nameOfCompetition: 'gdsc',
    image: 'gdsc',
    soloOrTeam: 'gdsc',
    about: 'gdsc',
    prize: '234',
    rulebook: 'gdsc',
    teams: 'desktop',
    minTeamMember : "gdsc",
    maxTeamMember : "gdsc"
  },
  {
    id: 2,
    clubOrganisingName : 'gdsc',
    nameOfCompetition: 'gdsc',
    image: 'gdsc',
    soloOrTeam: 'gdsc',
    about: 'gdsc',
    prize: '234',
    rulebook: 'gdsc',
    teams: 'desktop',
    minTeamMember : "gdsc",
    maxTeamMember : "gdsc"
  }
]

export default all_products;